// angular
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
} from "@ngrx/router-store";

// Error tracking
import * as Sentry from "@sentry/angular";

// components
import { AppComponent } from "./app.component";
import { ProjectListComponent } from "./projects/project-list/project-list.component";

// services
import { AuthEntityService } from "./auth/_services";
import { AuthStateService } from "./shared/_service/auth-state.service";
import { PaymentGuard } from "./shared/_service/payment.guard";

// modules
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppStoreModule } from "./store/app-store.module";
import { AuthModule } from "./auth/auth.module";

// plugins

// stores
import {sharedStore} from "astrakode-bc-library";
import { SharedStoreModule } from "./shared-store/shared-store.module";
import { UserPlanStoreModule } from "./user-plan-store/user-plan-store.module";

// libraries
import {
  AstrakodeBcLibraryModule,
  DialogService,
  DownloadService,
} from "astrakode-bc-library";
import { AstrakodeBcComposerModule } from "astrakode-bc-composer";
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
  TranslateService,
} from "@ngx-translate/core";

// captchas
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GlobalErrorHandler } from './global-error-handler';

export function createTranslateLoader(http: HttpClient) {
  const date = new Date();

  return new TranslateHttpLoader(
    http,
    "./assets/i18n/",
    `.json?token=${btoa(date.toDateString())}`
  );
}

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    // angular
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,

    //captcha
    RecaptchaV3Module,

    // modules
    AppRoutingModule,
    AppStoreModule,
    AuthModule,

    // plugins

    // libraries
    AstrakodeBcLibraryModule,
    AstrakodeBcComposerModule,

    // stores
    StoreRouterConnectingModule.forRoot(),
    SharedStoreModule,
    UserPlanStoreModule,

    //StoreModule.forRoot(reducers)
    //EffectsModule.forRoot(effects)

    // translation
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DialogService,
    DownloadService,
    AuthEntityService,
    AuthStateService,
    ProjectListComponent,
    PaymentGuard,
    {
      provide: RouterStateSerializer,
      useClass: sharedStore.RouterSerializer,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: environment.production ? false : true,
      }),
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.CAPTCHA_SITEKEY,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
