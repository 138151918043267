<section class="projects-list-wrapper"
>
    <ak-title
            class="project-list-title"
    >
        {{ translations.welcomeTitle | translate }}
    </ak-title>


    <ng-template #prevButton>
        <button class="btn_guide--modal">
            {{ translations.prev | translate }}
        </button>
    </ng-template>
    <ng-template #nextButton>
        <button class="btn_guide--modal">
            {{ translations.next | translate }}
        </button>
    </ng-template>

    <ng-template #doneButton>
        <button class="btn_guide--modal" id="btn_close_vt">
            {{ translations.done | translate }}
        </button>
    </ng-template>

    <ng-template #projectsHome>
        {{ translations.home | translate }}
    </ng-template>

    <div class="add-new-project-container">
        <a class="add-new-project" routerLink="/projects/new">
            <i class="iconoir-add-page"></i>
            <span>{{ translations.newProject | translate }}</span>
        </a>

        <a class="add-new-project" routerLink="/projects/marketplace/template">
            <i class="iconoir-multiple-pages"></i>
            <span>{{ translations.templateMarketPlace | translate }}</span>
        </a>
    </div>
    
    <lib-project-viewer
            [spinnerPage] = "spinnerPage"
            [viewMode]="viewMode"
            [viewModeType]="viewModeType"
            [workspaceToOpen]="workspaceToOpen"
            [loadingWorkspaceStore]="loadingWorkspace$"
            [workspaces$]="workspaces$"
            [projects$]="projects$"
            [seats$]="seats$"
            [productList$]="productList$"
            [loading]="workspaceLoading"
            (projectAction)="projectAction($event)"
            (workspaceAction)="workspaceAction($event)"
            (workspacesLoading)="isWorkspaceLoading($event)"
            (newWorkspaceEmitter)="newWorkspace()"
    ></lib-project-viewer>    

    <ng-template #projectHomeAdd>
        {{ translations.addEnd | translate }}
    </ng-template>
</section>

