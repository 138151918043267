import {ChangeDetectionStrategy, ChangeDetectorRef,Component, OnInit, ViewEncapsulation,
} from '@angular/core';
import {Project} from '../_models/project.model';
import {Observable, PartialObserver, pipe, Subject, Subscription} from 'rxjs';
import {ProjectsService, GUIDED_TOUR_KEY,} from '../_services';
import {Auth} from '../../auth/_model/auth.model';
import {AuthDataService} from '../../auth/_services';
import {UserEntityService} from '../../user/_services';
import {
    ProjectModalContentComponent,
    NewProjectModalComponent,
    NewWorkspaceModalComponent,
    SharedModalComponent,
    ToasterService,
    DialogService,
    MessageModalComponent,
    DeleteDialogComponent,
    ProjectConstants
} from 'astrakode-bc-library';
import {ActivatedRoute, Router} from '@angular/router';
// import * as fromStore from '../../shared-store/_store';
import {sharedStore} from "astrakode-bc-library";
import {Store} from '@ngrx/store';
import {User} from '../../user/_model';
import {GuideTourType} from '../_enums';
// import {
//     GetProjects,
//     getWorkspaces,
//     GetWorkspaces,
//     getOpenedWorkspace,
//     PopOpenProjects,
//     PopOpenWorkspace,
//     PushOpenWorkspace,
//     HeaderAction,
//     HEADER_ACTION,
//     getWorkspacesProjects,
//     getRemainingSeats,
// } from '../../shared-store/_store';
import {Actions, ofType} from '@ngrx/effects';
import {switchMap, takeUntil, take, tap, filter, distinctUntilChanged, skip} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {
    AuditService,
    AuditStatus,
    AuditType,
} from '../../shared/_service/audit.service';
import {DefaultWSResponse} from '../../shared/types';

import {environment} from '../../../environments/environment';
import {DeviceDetectorService} from 'ngx-device-detector';


export const NEW_RELEASE_KEY = 'newReleaseModal';


interface DownloadModalTranslations {
    welcomeTitle: string;
    new: string;
    home: string;
    prev: string;
    next: string;
    done: string;
    templateMarketPlace: string;
    recent: string;
    folders: string;
    newProject: string;
    addEnd: string;
}

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})

export class ProjectListComponent implements OnInit {
    loading$: boolean;
    loadingWorkspace$: boolean;
    projects: Project[];
    workspaces$: Observable<any[]>;
    projects$: Observable<any[]>;

    selected: Project;
    selectedWorkspace: Project;
    _projects$: Observable<Project[]>;
    _workspaces$: Observable<Project[]>;
    currentUser: Auth;
    projectShared = false;
    projectSelected = false;
    latestProjects: Project[] = [];
    options$: Observable<any>;
    seats$: Observable<number>;
    productList$: Observable<any>;

    users$: Observable<User[]>;
    user: User;

    optionsSubject = new Subject<number>();
    optionsResultSubject = new Subject<any>();
    loadingOptionsSubject = new Subject<boolean>();

    creatingProject = false;

    workspaceLoading = false;

    showLoader = false;
    translations: DownloadModalTranslations = {
        prev: 'Actions.Prev',
        next: 'Actions.Next',
        done: 'Actions.Done',
        welcomeTitle: 'Home.WelcomeTitle',
        new: 'Projects.New',
        home: 'Joyride.Projects.Home',
        templateMarketPlace: 'ProjectFlow.UseTemplate',
        recent: 'ProjectViewer.Recent',
        folders: 'ProjectViewer.Folders',
        newProject: 'ProjectFlow.New',
        addEnd: 'Joyride.PortalHeader.User'
    };

    headerActionSubscription$: Subscription;

    isLoadingNewProject: boolean;
    isLoadingNewLogic: boolean;

    projectDomains: any;

    // subscriptions
    private destroy$: Subject<boolean> = new Subject<boolean>();

    private version$: Subject<boolean>;

    listProjectData: any;

    // new

    spinnerPage: boolean = false;
    viewMode = 'list';
    viewModeType = 'recent';
    constructor(
        private ref: ChangeDetectorRef,
        private authenticationService: AuthDataService,
        private userService: UserEntityService,
        private dialogService: DialogService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store<sharedStore.SharedStoreState>,
        private projectsService: ProjectsService,
        private toasterService: ToasterService,
        private actions$: Actions,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private deviceDetectorService: DeviceDetectorService,
        private auditService: AuditService
    ) {
        this.users$ = userService.entities$;
        this.currentUser = this.authenticationService.currentUserValue;
        this.loading$ = true;
        this.loadingWorkspace$ = true;
        this.workspaces$ = this.store.select(sharedStore.getWorkspaces);
        this.projects$ = this.store.select(sharedStore.getWorkspacesProjects);
        this.seats$ = this.store.select(sharedStore.getRemainingSeats);
        this.productList$ = this.store.select(sharedStore.getProductsList);
        this.users$
            .pipe(takeUntil(this.destroy$))
            .subscribe((users) => {
                this.user = users[0];
            });
    }

    byId(project: Project) {
        return project.id;
    }

    workspaceToOpen;

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(res=> {
            if (res.hasOwnProperty("wsid")){
                this.workspaceToOpen = res["wsid"];
                this.viewMode = "card";
                this.viewModeType = "folders";
            }
        });

        // Get projects and save it in the ngrx Store
        this.store.dispatch(new sharedStore.PopOpenWorkspace());

        // this.store.select(sharedStore.isGetPairsCallSuccess).pipe(
        //     takeUntil(this.destroy$), 
        //     filter(isGetPairsCallSuccess => isGetPairsCallSuccess)
        // ).subscribe(isGetPairsCallSuccess => {
        //     this.getWorspaces();
        // });
        
        this.userService.getByKey(0);
        this.store
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: any) => {
                if (data.sharedStore.projects.loading === false) {
                    this.loading$ = data.sharedStore.projects.loading;
                }
            });
        this.store.subscribe((data: any) => {
            if (data.sharedStore.workspaces.loading === false) {
                this.loadingWorkspace$ = data.sharedStore.workspaces.loading;
            }
        });
        this.creatingProject = false;
        this.projectSelected = false;

        // Header Action
        this.headerActionSubscription$ = this.actions$
            .pipe(ofType(sharedStore.HEADER_ACTION), takeUntil(this.destroy$))
            .subscribe((action: sharedStore.HeaderAction) => {
                const {key, data} = action.payload;

                switch (key) {
                    case 'onHelp':

                        localStorage.setItem('onboardingTourDisabled', 'false');
                        this.startGuideTour(true);
                        break;
                    default:
                        break;
                }
            });

        const domainsData = this.projectsService.getProjectDomainsVariable();
        if (!domainsData) {
            this.projectsService.getProjectDomains().pipe(take(1)).subscribe((data: any) => {
                this.projectsService.setProjectDomains(data);
                const {listBlockchainType} = data;
                this.listProjectData = data;
                this.projectDomains = listBlockchainType;
            });
        } else {
            const {listBlockchainType} = domainsData;
            this.listProjectData = domainsData;
            this.projectDomains = listBlockchainType;
        }
    }

    ngOnDestroy() {
        if (this.headerActionSubscription$) {
            this.headerActionSubscription$.unsubscribe();
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    startGuideTour(skipCanStart?: boolean) {
    }

    ngAfterViewInit() {
        this.startGuideTour();
    }

    onDone() {
    }

    close() {
        this.selected = null;
    }

    /**
     * Gets all the projecct from the data store
     *
     * @memberof ProjectListComponent
     */
    getProjects() {
        this.store.dispatch(new sharedStore.GetProjects());

        this.close();
    }

    /**
     * Opens a details project modal
     *
     * @param {*} project
     * @memberof ProjectListComponent
     */
    openProjectDialog(project: any) {

        this.projectsService.setProjectDomains(this.listProjectData);

        if (this.listProjectData === undefined) {
            this.projectsService.getProjectDomains().pipe(take(1)).subscribe((projectDomain: any) => {
                this.openDialog(project, projectDomain);
            });
        } else {
            this.openDialog(project,  this.listProjectData);
        }
    }

    openDialog(project, data): void {
        const {listProjectType, listBlockchainNetwork, listBlockchainSmartContract} = data;

        let projectTypeLabel = 'network';
        let projectTypeDescription = '';
        const projectType = listProjectType.find((item) => item.id == project.type);

        if (projectType.domainValue == 'bc') {
            // bc is a Network
            projectTypeLabel = 'network';
            this.projectDomains = listBlockchainNetwork;
        } else {
            // bt is a Smart Contract
            projectTypeLabel = 'logic';
            this.projectDomains = listBlockchainSmartContract;
        }

        const technology = listProjectType.find(el => el.id === project.type);
        if (technology !== undefined) {
            projectTypeDescription = technology.description;
        }

        const bcTypeTitle: string = this.projectDomains.find((item) => item.id == project.bcType)?.domainValue;

        const newBranchData = {};
        let bcVersion;
        let screen;
        let groupId;
        let branchId;
        this.projectsService.getProjectData(project.id).pipe(
            take(1),
            tap(branchData => {
                screen = branchData.listBranch[0]['screen'];
                groupId = branchData.listBranch[0].group.id;
                branchId = branchData.listBranch[0]['id'];
            }),
            switchMap(branchData => this.projectsService.getProjectVersions(project.bcType)),
            tap(({listDomain}) => bcVersion = listDomain.find((item) => item.uuidPointer == groupId).domainValue),
            switchMap(listDomain => 
                this.dialogService.open(ProjectModalContentComponent, {
                    ...project,
                    branchId,
                    bcTypeTitle,
                    bcVersion,
                    screen,
                    newBranchData,
                    projectTypeLabel,
                    projectTypeDescription,
                    environment,
                    class: 'open-project'
                }))
        ).subscribe((data: any) => {
            const {action, payload} = data || {};
            if (action) {
                if (action == "open") {
                    if (payload.projectTypeLabel === "logic") {
                        //logic identify the smart contract project, developed with React ide
                        window.location.href = payload.url;
                    } else {
                        this.router.navigateByUrl(payload.url);
                    }
                } else if (action == 'share') {
                    this.openShareDialog(project);
                } else if (action == 'delete') {
                    this.deleteProject(project);
                } else if (action == 'duplicate') {
                    this.duplicateProject(project);
                }
            }
        });
    }

    /**
     * Opens a share project modal
     *
     * @param {*} project
     * @memberof ProjectListComponent
     */
    openShareDialog(project) {
        let share = true;

        const branchesObservable = this.projectsService.getProjectData(project.id);

        const bcVersionObservable = branchesObservable.pipe(
            switchMap((branchData) => {
                return new Observable((subscribe: PartialObserver<any>) => {
                    subscribe.next({branchData});
                });
            })
        );

        bcVersionObservable
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                const branchId = data.branchData.listBranch[0].id;

                this.dialogService.open(SharedModalComponent, {class: 'form-dialog'})
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                        this.users$
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((user) => {
                                if (data) {
                                    const formData = data;
                                    const sharedData = {
                                        uuid: branchId,
                                        username: data.email,
                                    };
                                    if (share) {
                                        this.projectsService.shareProject(sharedData).pipe(take(1)).subscribe(
                                            (data: any) => {
                                                if (data && !(data.infos && data.infos.length > 0)) {
                                                    // data.context.userName is the email of who is sharing
                                                    // sharedData.username is the email of to whom the project is shared
                                                    const crmShare = {
                                                        email: data.context.userName,
                                                        user: user[0].name,
                                                        message: formData.message,
                                                        projectshareEmail: sharedData.username,
                                                        projectType: data.project.type == '1' ? 'network' : 'logic',
                                                        projectId: data.project.id
                                                    };
                                                    // we need to notify the CRM of the sharing project
                                                    this.projectsService.shareProjectCRM(crmShare).pipe(take(1)).subscribe(
                                                        (data) => {
                                                            share = false;
                                                            this.translateService.get('SuccessMessages.ProjectShared')
                                                                .pipe(takeUntil(this.destroy$))
                                                                .subscribe((data: string) => {
                                                                    this.toasterService.success(data);
                                                                });
                                                        },
                                                        (error) => {
                                                            share = false;
                                                            this.translateService.get('ErrorMessages.ProjectShared')
                                                                .pipe(takeUntil(this.destroy$))
                                                                .subscribe((data: string) => {
                                                                    this.toasterService.error(data);
                                                                });
                                                        }
                                                    );
                                                } else {
                                                    share = false;
                                                    this.translateService.get('ErrorMessages.ProjectShared')
                                                        .pipe(takeUntil(this.destroy$))
                                                        .subscribe((data: string) => {
                                                            this.toasterService.error(data);
                                                        });
                                                }
                                            },
                                            (error) => {
                                                share = false;
                                                this.translateService
                                                    .get('ErrorMessages.ProjectShared')
                                                    .pipe(takeUntil(this.destroy$))
                                                    .subscribe((data: string) => {
                                                        this.toasterService.error(data);
                                                    });
                                            }
                                        );
                                    }
                                }
                            });
                    });
            });
    }

    /**
     * Opens a delete project
     *
     * @param {*} project
     * @memberof ProjectListComponent
     */
    deleteProject(project: any) {
        this.dialogService.open(DeleteDialogComponent, {class: 'warning-dialog', type:'project'})
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                if (!result) {
                    this.spinnerPage = false;                    
                } else {
                    this.spinnerPage = true;
                    console.log("spinnerPage = ", this.spinnerPage);
                    this.store.dispatch(new sharedStore.DeleteProject(project));
                    this.store.select(sharedStore.getWorkspacesLoading).pipe(
                        takeUntil(this.destroy$),
                        skip(1)
                    )
                    .subscribe((users) => {
                        this.spinnerPage = false;
                        console.log("spinnerPage = ", this.spinnerPage);
                    });
                        // this.projectsService
                //     .deactivateBranches(project)
                //     .pipe(take(1))
                //     .subscribe((response: DefaultWSResponse) => {
                //         if (response?.infos?.length) {
                //             this.auditService.generateAudit(
                //                 AuditStatus.Error,
                //                 AuditType.ProjectCancellation,
                //                 project.id
                //             );
                //         }
                //         this.getWorspaces();
                //     });
                }
            });
    }

    /**
     Gets all the workspaces from the data store*
     @memberof ProjectListComponent*/
    getWorspaces() {
        this.store.dispatch(new sharedStore.GetWorkspaces());
    }

    /**
     * Create workspace
     *
     * @memberof ProjectListComponent
     */
    newWorkspace(): void {
        this.dialogService.open(NewWorkspaceModalComponent, {class: 'form-dialog'})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data) {
                    this.projectsService.createWorkspace(data)
                        .pipe(take(1))
                        .subscribe((data) => {
                            this.getWorspaces();
                            this.translateService
                                .get('SuccessMessages.NewWorkspace')
                                .pipe(takeUntil(this.destroy$))
                                .subscribe((data: string) => {
                                    this.toasterService.success(data);
                                });
                        });
                }
            });
    }

    /**
     * Edit workspace
     *
     * @memberof ProjectListComponent
     */
    editWorkspace(workspace) {
        this.dialogService
            .open(NewWorkspaceModalComponent, {workspace, class: 'form-dialog'})
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                if (data) {
                    this.projectsService.updateworkspace(data)
                        .pipe(take(1))
                        .subscribe((data) => {
                            this.getWorspaces();
                            this.translateService
                                .get('SuccessMessages.UpdateWorkspace')
                                .subscribe((data: string) => {
                                    this.toasterService.success(data);
                                });
                        });
                }
            });
    }

    /**
     * Opens a delete workspace
     *
     * @param {*} workspace
     * @memberof ProjectListComponent
     */
    deleteWorkspace(id: string) {
        this.dialogService.open(DeleteDialogComponent, {class: 'warning-dialog', type:'workspace'})
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                if (!result) {
                    return;
                }

                this.projectsService.deactivateWorkspace(id)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.getWorspaces();
                        this.translateService
                            .get('SuccessMessages.DeleteWorkspace')
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((data: string) => {
                                this.toasterService.success(data);
                            });
                    });
            });
    }

    unlockProject(id: any): void {
        this.store.dispatch(new sharedStore.UpdateWorkspaceSetProjectAccessible(id));
    }

    duplicateProject(project: any): void {
        // this.projectsService.duplicateProject(project).subscribe(resp => this.getWorspaces());
        this.store.dispatch(new sharedStore.DuplicateProject(project));
    }

    projectAction(data: {project: any, action: string, viewMode: string, viewModeType: string}): void {        
        this.viewMode = data.viewMode;
        this.viewModeType = data.viewModeType;
        switch (data.action) {
            case 'open':
                this.openProjectDialog(data.project);
                break;
            case 'open-direct':
                this.spinnerPage = true;
                this.openProjectDirect(data.project);
                break;
            case 'share':
                this.openShareDialog(data.project);
                break;
            case 'delete':                
                this.deleteProject(data.project);
                break;
            case 'unlock':
                this.unlockProject(data.project);
                break;
            case 'duplicate':
                this.duplicateProject(data.project);
                break;
        }
    }

    workspaceAction(data: {workspace: any, action: string}): void {
        switch (data.action) {
            case 'edit':
                this.editWorkspace(data.workspace);
                break;
            case 'delete':
                this.deleteWorkspace(data.workspace.id);
                break;
        }
    }

    isWorkspaceLoading(event: boolean): void{
        this.workspaceLoading = event;
    }

    openProjectDirect(project: any): void {
        const bcType = project.bcType == 3 ? "hf" : "s";
        if (project.type === '2') {
            let branchId;
            this.projectsService.getProjectData(project.id).pipe(
                take(1),
                tap(branchData => {
                    branchId = branchData.listBranch[0]['id'];
                })
            ).subscribe(resp => {
                this.spinnerPage = false;
                const urlAppend = `?accessible=${project.accessible}&branchId=${branchId}&type=logic&bc_type=${bcType}`;
                window.location.href =
                window.location.hostname === 'localhost'
                    ? `${environment.composerLogicLocal}/${project.id}${urlAppend}`
                    : `${environment.composerLogic}/${project.id}${urlAppend}`;
            });            
        } else {
            this.spinnerPage = false;
            this.router.navigateByUrl(`/projects/edit/${project.id}?type=network&bc_type=${bcType}`);
        }
    }
}
