import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {EMPTY, Observable, forkJoin, of} from "rxjs";
import {map, catchError, switchMap, tap, withLatestFrom, filter} from "rxjs/operators";
import { ProjectsService } from "../../../projects/_services/projects.service";
import {ToasterService} from "astrakode-bc-library";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import {sharedStore} from "astrakode-bc-library";
import { PremiumDialogComponent } from "astrakode-bc-composer";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class WorkspacesEffects {
  constructor(
    private actions$: Actions,
    private projectsService: ProjectsService,
    private toasterService: ToasterService,
    private store: Store<sharedStore.SharedStoreState>,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) { }

    /**
   * Return all the templates from the store
   *
   * @memberof WorkspacesEffects
   */
    getLatestTemplates$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(sharedStore.GET_LATEST_TEMPLATES),
        withLatestFrom(() => this.store.select(sharedStore.getTempaltes)),
        filter((action, templates) => !templates),
        switchMap(templates => [new sharedStore.GetTemplates()])
      );
    });

  /**
   * Return all the templates from the store
   *
   * @memberof WorkspacesEffects
   */
  getTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.GET_TEMPLATES),
      switchMap((action: sharedStore.GetTemplates) => {
        return this.projectsService.getTemplates(action.payload).pipe(
            tap(result => {
                if (result && result.errorTranslations) {
                    localStorage.setItem('errorTranslations', result.errorTranslations);
                }
            }),
            switchMap(result => {
              // result.templates.map(template => {
              //   template.accessible = true;
              //   template.bcType = template.bcType;
              //   template.timestamp = template.insTmst;
              // });
              return ([
                  new sharedStore.GetTemplatesSuccess(result.categories)
              ])
            }),
            catchError((e) => {
              return EMPTY;
            })
        );
      })
    );
  });

  /**
   * Return all the user workspaces and save them in the storage
   *
   * @memberof WorkspacesEffects
   */
  getWorkspaces$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sharedStore.GET_WORKSPACES),
      switchMap((action: sharedStore.GetWorkspaces) => {
        return this.projectsService.getWorkspaces().pipe(
          tap((result: any) => {
              if (result && result.errorTranslations) {
                  localStorage.setItem('errorTranslations', result.errorTranslations);
              }
          }),
          switchMap((result: any)  => (
                  [
                  new sharedStore.GetWorkspacesSuccess(result.listWorkspace),
                  new sharedStore.GetWorkspacesProjectsSuccess(result.listProject),
                  new sharedStore.SetRemainingSeats(result.remainingSeats)
                  ])),
          catchError((e) => {
            return EMPTY;
          })
        );
      })
    );
  });

  // getWorkspacesProjectsSuccess$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(sharedStore.GET_WORKSPACES_PROJECTS_SUCCESS),
  //     switchMap((projectList: any[]) => {
  //       const apiCalls = projectList.map(project => this.projectsService.getProjectData(project.id));
  //       // Use forkJoin to wait for all API calls to complete
  //       return forkJoin(apiCalls);
  //     }),
  //     switchMap((results: [])  => {
  //       let resultsList = [];

  //       return resultsList;
  //     })
  //   );
  // });

    updateWorkspaceSetProjectAccessible$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sharedStore.UPDATE_WORKSPACE_SET_PROJECT_ACCESSIBLE),
            switchMap((action: sharedStore.UpdateWorkspaceSetProjectAccessible) => {
                return this.projectsService.setProjectAccessible(action.payload).pipe(
                  switchMap((e: any) => {
                        if (e.hasOwnProperty('accessible') && !e.accessible) {
                            // this.translateService
                            //     .get('ErrorMessages.ActivateProject')
                            //     .subscribe((data: string) => {
                            //         this.toasterService.error(data);
                            //     });

                            const error = JSON.parse(localStorage.getItem('errorTranslations'));
                            const lang = localStorage.getItem("lang") || "enUS";

                            let dialogRef = this.dialog.open(PremiumDialogComponent, {
                              data: {
                                  constraintsErrorsList: [{
                                    label: "STRIPE_NO_SEATS_LEFT_ERR",
                                    description: error[lang]["STRIPE_NO_SEATS_LEFT_ERR"],
                                    type: 1,
                                    diagramElementId: 0,
                                    attributeId: 0
                                  }]
                              },
                            });

                            return [new sharedStore.UpdateWorkspaceSetProjectAccessibleError(e.remainingSeats)];
                        }else {
                            this.translateService
                                .get('SuccessMessages.ActivateProject')
                                .subscribe((data: string) => {
                                    this.toasterService.success(data);
                                });
                            return [
                              new sharedStore.UpdateWorkspaceSetProjectAccessibleSuccess(e),
                              new sharedStore.UpdateProductsCounterAfterAddProject(e)
                            ];
                        }
                    }),
                );
            })
        );
    });

    getTags$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(sharedStore.GET_TAGS),
        withLatestFrom(() => this.store.select(sharedStore.getTags)),
        filter((action, tags) => Object.keys(tags).length === 0),
        switchMap((action: any) => {
          return this.projectsService.getTags().pipe(
            switchMap((result) => ([new sharedStore.UpdateTagsSuccess(result)])),
            catchError((e) => of(new sharedStore.UpdateTagsError()))
          );
        })
      );
    });

}
