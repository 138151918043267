import {EMPTY, Observable, Subject, from, throwError} from "rxjs";
import {Component, NgZone} from "@angular/core";
import {Auth as AuthAWS, Hub} from "aws-amplify";
import {Router} from "@angular/router";
import {AmplifyService} from "aws-amplify-angular";
import {AuthDataService} from "../_services/auth-data.service";
import {AuthStateService} from "../../shared/_service/auth-state.service";
import {StripeService} from "../../shared/_service/stripe.service";
import {DialogService} from "astrakode-bc-library";
import {
    AuthModalComponent,
    RecoverConfirmModalComponent,
    RecoverModalComponent,
    ToasterService,
} from "astrakode-bc-library";
import {TranslateService} from "@ngx-translate/core";
import {SharedStoreModule} from "../../shared-store/shared-store.module";
import {Store} from "@ngrx/store";
// import {Logout} from "../../shared-store/_store/_actions/logout.action";
import {sharedStore} from "astrakode-bc-library";
import {ProjectsService} from "../../projects/_services";
import {catchError, delay, switchMap, take, tap} from "rxjs/operators";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {AuditService, AuditStatus, AuditType} from "../../shared/_service/audit.service";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";

export interface LoginFormOutput {
    email: string;
    password: string;
    remember: boolean;
}

interface DownloadModalTranslations {
    title: string;
    mainTitle: string;
    subTitle: string;
}

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
    public errorSubject: Subject<any> = new Subject<any>();
    loading$: Observable<boolean>;
    userName: string;
    email: "";
    loginErrMsg: string;

    translations: DownloadModalTranslations = {
        title: "Login.Title",
        mainTitle: "Login.MainTitle",
        subTitle: "Login.SubTitle"
    };

    constructor(
        //private authEntityService: AuthEntityService,
        private router: Router,
        private amplifyService: AmplifyService,
        private zone: NgZone,
        private authDataService: AuthDataService,
        private authService: AuthStateService,
        private dialogService: DialogService,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private store: Store<SharedStoreModule>,
        private stripeService: StripeService,
        private projectsService: ProjectsService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private auditService: AuditService
    ) {
        //this.loading$ = authEntityService.loading$;

        AuthAWS.currentAuthenticatedUser()
            .then(() => {
                this.zone.run(() =>
                    this.router.navigate(["/projects/home"], {
                        replaceUrl: true,
                        //skipLocationChange: true,
                    })
                );
            })
            .catch((err) => {
            });
    }


    onGoogleLogin() {
        AuthAWS.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        });
    }

    onLinkedinLogin() {
        AuthAWS.federatedSignIn({
            customProvider: "LinkedIn",
        });
    }

    /**
     * Log in the user the cognito services
     *
     * @param {*} loginInfo
     * @memberof LoginComponent
     */
    onLogin(loginInfo: any) {
        loginInfo.remberMe
            ? AuthAWS.configure({storage: window.localStorage})
            : AuthAWS.configure({storage: window.sessionStorage});

        localStorage.setItem("remember", loginInfo.remberMe.toString());

        let isLoggedIn: boolean = false;
        from(AuthAWS.signIn(loginInfo.user)).pipe(
            take(1),
            switchMap(signInResp => from(AuthAWS.signOut({ global: true }))),
            tap(signOutResp => localStorage.removeItem("Auth")),
            delay(300),
            switchMap(signOutResp => this.authDataService.signin(loginInfo.user)),
            // catchError((err: any) => {
            //     console.log("DURING LOGIN: ", err);
            //     this.authService.logout();
            //     return EMPTY;
            // })
        ).subscribe(
            (data) => {
                //the next code i commented because now the login event is registered in the AK_PostAuthenticationTrigger during the cognito postoauthentication trigger
                // this.auditService.generateAudit(
                // AuditStatus.Success,
                // AuditType.LoginS,
                // JSON.stringify({
                //     event: "AK_LOGIN",
                //     eventDescription: "User logged in Successfully.",
                //     userEmail: loginInfo.user.username,
                //     techSpec: {
                //     serviceName: "onLogin",
                //     serviceInput: {
                //         username: loginInfo.user.username,
                //         password: "*****"
                //     },
                //     },
                //     details: {
                //     ...data
                //     }
                // })
                // );
                this.afterLoginSuccesslocale(loginInfo.user.username, loginInfo.locale);
            },
            (error) => {
                let showToastMsg: boolean = true;
                if (error.code === "UserNotConfirmedException") {
                    showToastMsg = false;
                    this.loginErrMsg = error.message;
                    this.confirmAccount(loginInfo);
                } else if (error.code === "UserNotFoundException") {
                    showToastMsg = false;
                    this.loginErrMsg = error.message;
                //generateAudit could not be called because we do not have an authentication token
                // from(this.auditService.generateAudit(
                //   AuditStatus.Error,
                //   AuditType.LoginS,
                //   JSON.stringify({
                //     event: "AK_LOGIN",
                //     eventDescription: "User Not Found Exception.",
                //     userEmail: loginInfo.user.username,
                //     techSpec: {
                //       serviceName: "onLogin",
                //       serviceInput: {
                //         username: loginInfo.user.username,
                //         password: "*****"
                //       },
                //     }
                //   })
                // )).pipe(take(1)).subscribe();
                } else if (isLoggedIn) {
                AuthAWS.signOut({ global: true }).then(
                    (data) => {
                    localStorage.removeItem("Auth");
                    this.store.dispatch(new sharedStore.Logout());
                    });
                }
                this.errorSubject.next(error);
                if (error.message && showToastMsg) {
                this.toasterService.error(error.message);
                }
            }
        );
  }

    afterLoginSuccesslocale(username, locale) {
        if (locale) {
            localStorage.setItem("lang", locale);
        } else {
            localStorage.removeItem("lang");
        }
        this.router.navigate(["/projects/home"]).then(() => {
            this.authService.login();
        });
    }

    /**
     * Confirms the user account using the cognito service
     *
     * @param {*} userData
     * @param {number} [option=0]
     * @memberof LoginComponent
     */
    confirmAccount(userData, option = 0) {
        this.dialogService.open(AuthModalComponent, {class: 'form-dialog'}).subscribe((data) => {
            if (data) {
                if (data.code) {
                    this.authDataService.cognitoConfirmSignup(userData.user.username, data.code).subscribe(
                        (data) => {
                            if (option === 0) {
                                this.authDataService.signin(userData.user).subscribe((data) => {
                                    const defaultWorkspaceData = {
                                        name: "Default Workspace",
                                        description: "The default workspace.",
                                        username: userData.user.username
                                    };

                                    this.projectsService.createWorkspace(defaultWorkspaceData).pipe(take(1)).subscribe((data) => {
                                        this.router.navigate(["/projects/home"]).then(() => {
                                            this.authService.login();
                                        });
                                    });

                                });
                            } else {

                                this.errorSubject.next({
                                    message: "Account has been confirmed!",
                                });
                                this.translateService.get("SuccessMessages.AccountConfirm").subscribe((data: string) => {
                                    this.toasterService.success(data);
                                });
                                this.onRecoverPassword(1);
                            }
                        },
                        (error) => {
                            this.errorSubject.next(error);
                            //this.toasterService.error(error.message);
                            console.error(error.message);
                            this.confirmAccount(userData, option);
                        }
                    );
                } else if (data.action) {
                    this.authDataService.cognitoResendCode(userData.user.username).subscribe(
                        (data) => {
                            this.errorSubject.next({message: "Code sent"});
                            this.translateService.get("SuccessMessages.CodeSent").subscribe((data: string) => {
                                this.toasterService.success(data);
                            });
                            this.confirmAccount(userData, option);
                        },
                        (error) => {
                            this.errorSubject.next(error);
                            this.toasterService.error(error.message);
                        }
                    );
                }
            } else {
                this.translateService.get("ErrorMessages.Code").subscribe((data: string) => {
                    this.errorSubject.next({message: data});
                    this.toasterService.error(data);
                });
                //this.confirmAccount(userData);
            }
        });
    }

    /**
     * Recovers the user password usinght the cognito services
     *
     * @param {number} [type=0]
     * @memberof LoginComponent
     */
    onRecoverPassword(type = 0) {
        this.dialogService.open(RecoverModalComponent, {class: 'form-dialog'}).subscribe(
            (userInfo) => {
                if (userInfo) {
                    let email = userInfo.email;
                    this.authDataService.cognitoInitChangePassword(userInfo.email).subscribe(
                        (data) => {
                            this.translateService.get("Login.Recovery").subscribe((data: string) => {
                                this.toasterService.info(data);
                            });
                            this.dialogService.open(RecoverConfirmModalComponent, {class: 'form-dialog'}).subscribe(
                                (data) => {
                                    if (data) {
                                        let userinfo = {
                                            username: email,
                                            password: data.password,
                                        };
                                        this.authDataService.cognitoConfirmChangePassword(
                                            email,
                                            data.code,
                                            data.password
                                        ).subscribe(
                                            (data) => {
                                                this.authDataService.signin(userinfo).subscribe(
                                                    (data) => {
                                                        AuthAWS.signOut({global: true}).then(
                                                            (data) => {
                                                                localStorage.removeItem("Auth");
                                                                this.store.dispatch(new sharedStore.Logout());

                                                                //global: true is done in order to invalidate all the token. If the user is logged in also with others devices after one hour the session will expire and to get a new toke the user will need to do the login again
                                                                this.authDataService.signin(userinfo).subscribe(
                                                                    (data) => {
                                                                        if (type === 0) {
                                                                            this.errorSubject.next({
                                                                                message: "Password updated!",
                                                                            });
                                                                            this.translateService.get("SuccessMessages.PasswordUpdate").subscribe((data: string) => {
                                                                                this.toasterService.success(data);
                                                                            });
                                                                            this.router.navigate(["/projects/home"]).then(() => {
                                                                                this.authService.login();
                                                                            });
                                                                        } else {
                                                                            AuthAWS.currentAuthenticatedUser().then(
                                                                                (data) => {
                                                                                    this.translateService.get("SuccessMessages.PasswordUpdate").subscribe((data: string) => {
                                                                                        this.toasterService.success(data);
                                                                                    });

                                                                                    this.router.navigate(["/projects/home"]).then(() => {
                                                                                        this.authService.login();
                                                                                    });
                                                                                }
                                                                            );
                                                                        }
                                                                    },
                                                                    (error) => {
                                                                        this.errorSubject.next(error);
                                                                        this.toasterService.error(error.message);
                                                                    }
                                                                );
                                                            }
                                                        )
                                                    },
                                                    (error) => {
                                                        this.errorSubject.next(error);
                                                        this.toasterService.error(error.message);
                                                    }
                                                );
                                            },
                                            (error) => {
                                                this.errorSubject.next(error);
                                                this.toasterService.error(error.message);
                                            }
                                        );
                                    } else {
                                        this.translateService.get("ErrorMessages.NoInfo").subscribe((data: string) => {
                                            this.errorSubject.next({
                                                message: data,
                                            });
                                            this.toasterService.error(data);
                                        });
                                    }
                                },
                                (error) => {
                                    console.error(error.message);
                                }
                            );
                        },
                        (error) => {
                            this.translateService.get("Login.Recovery").subscribe((data: string) => {
                                this.toasterService.error(data);
                            });
                            if (error.code === "LimitExceededException") {
                                this.errorSubject.next(error);
                                this.toasterService.error(error.message);
                            } else if (error.code === "InvalidParameterException") {
                                this.errorSubject.next(error);
                                this.toasterService.error(error.message);
                                let user = {
                                    user: {
                                        username: userInfo.email,
                                    },
                                };
                                this.confirmAccount(user, 1);
                            } else {
                                this.errorSubject.next(error);
                                if (error.code === "UserNotFoundException") {
                                } else {
                                    this.toasterService.error(error.message);
                                }
                            }
                        }
                    ),
                        (error) => {
                            this.errorSubject.next(error);
                            this.toasterService.error(error.message);
                        };
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }
}
